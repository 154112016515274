var $    = require('jquery'),
    util = require('./util.js'),
    touch = require('has-touch')
    ;

module.exports = {
  
  /** 
   * Cached DOM elements 
   */
  dom: {      
    win: null,
    up: null,
    share: null,
    contact: null
  },
  
  /**
   * Is Up button visible 
   */
  isUpVisible: false,
  
  /**
   * Is footer / share visible 
   */
  isFollowVisible: true,
  
  /**
   * Vector of scroll 
   */
  vy: 0,  
  
  /**
   * Scroll position 
   */
  ty: 0,
  
  /**
   * Cache footer height 
   */
  footerHeight: 0,
  
  /**
   * Cache DOM elements 
   */
  init: function () {
    
    this.dom.win     = $(window);
    this.dom.up      = $('.up');
    this.dom.share   = $('.follow');
    this.dom.contact = $('nav.secondary-nav .contact');

  },
  
  /**
   * Get scroll position and vector 
   */
  update: function () {
    
    var scrollTop = this.dom.win.scrollTop();
    
    this.vy = scrollTop - this.ty;
    this.ty = scrollTop;
    
    if (this.vy !== 0) {
      this.updateNav();
    }    
  },
  
  /**
   * Show and add interaction to up button 
   */
  showUpButton: function () {
      
    // Show and add scroll to top functionality 
    this.dom.up
      .addClass('show')
      .on('click', function () {
        $('html, body').animate({
          scrollTop: 0
        }, "slow");
      }
    );
    
    this.isUpVisible = true;
  },

  /**
   * Hide and remove interaction from up button 
   */
  hideUpButton: function () {
  
    this.dom.up
      .removeClass('show')
      .off('click');
      
    this.isUpVisible = false;
  },
  
  /**
   * Show and add interaction to follow button 
   */
  showFollowButton: function () {
      
    // Show and add scroll to top functionality 
    this.dom.share
      .removeClass('hide')
      .on('click', function () {}
    );
    
    this.isFollowVisible = true;
  },

  /**
   * Hide and remove interaction from follow button 
   */
  hideFollowButton: function () {
  
    this.dom.share
      .addClass('hide')
      .off('click');
      
    this.isFollowVisible = false;
  },
  
  
  getFooter: function () {
    
    if (this.isProject()) {
      return $('.next-prev');
    } else {
      return $('footer');
    }    
  },
  
  isProject: function () {    
    return $('body').hasClass('project');
  },
  
  isFooterVisible: function () {
    
    var bottom = this.dom.win.scrollTop() + this.dom.win.height(),
        footer = this.getFooter();
        
    return bottom > util.getDocumentHeight() - footer.height();    
  },
  
  updateUpButton: function () {
        
    // Check project footer 
    if (this.isProject() && this.vy !== 0) {
            
      if (this.isFooterVisible()) {
        
        // Make sure up is hidden
        if (this.isUpVisible) {
          this.hideUpButton(); 
        }
        
        // Return early 
        return;
      }
    }
             
    // Should we show or hide up button? 
    if (this.vy > 0) {
      
      // Scrolled down, so yes.
      if (!this.isUpVisible) {
        this.showUpButton();
      }             
    } else if (this.vy < 0) {

      // Scroll up, so no
      if (this.isUpVisible) {
        this.hideUpButton();
      }
    } else {
      // No movement.
    }          
  },
  
  updateFollowButton: function () {
    
    if (this.isProject() && this.vy !== 0) {
      
      if (this.vy > 0) {
        
        // Scrolled down
        if (this.isFollowVisible && this.isFooterVisible()) {
          this.hideFollowButton();
        }
        
      } else if (this.vy < 0) {
        
        // Scrolled up // Scrolled down
        if (!this.isFollowVisible && !this.isFooterVisible()) {
          this.showFollowButton();
        }
      }
    }
  },
  
  updateFooterMargin: function () {
    
    this.footerHeight = this.getFooter().height();
        
    if (this.isProject()) {      
      $('body.project .section-background').eq(0).css('margin-bottom', this.footerHeight);
    } else {
      $('.section-background').eq(0).css('margin-bottom', this.footerHeight);
    }
  },
  
  updateFooter: function () {
    
    if (touch) return;
    
    var bottom, footer, inner, opacity, scale, x;
    
    bottom   = this.dom.win.scrollTop() + this.dom.win.height();
    footer   = this.getFooter();
    inner    = footer.find('.wrapper');
    x        = (util.getDocumentHeight() - bottom) / footer.height();
    
    scale    = -Math.min(1, x) * 20;
    opacity  = 1 - Math.min(1, x) * 0.5;
    
    inner.css({
      opacity: opacity,
      transform: 'translate3d(0,' + scale + 'px, 0)'
    });
    
  },
  
  updateNav: function () {
    this.updateUpButton();
    this.updateFollowButton();
    this.updateFooterMargin();
    this.updateFooter();
  }
};