module.exports = {
  
  create: function (name, value, days, date, expires) {
    
    expires = "";
    
    if (days) {
      
      date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      
      expires = "; expires=" + date.toGMTString();
    }
    
    document.cookie = name + "=" + value + expires + "; path=/";
  },
  
  read: function (name, cookies, index, cookie) {
    
    name = name + '=';
    
    cookies = document.cookie.split(';');
    
    for(index = 0; index < cookies.length; ++index) {
      
      cookie = cookies[index].trim();
      
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    
    return null;    
  },
  
  erase: function (name) {
    this.create(name, "", -1);
  }
};