var touch = require('has-touch'),
    $     = require('jquery')
    ;
  
module.exports = {
    
  isOpen: false,
  
  init: function () {
    
    var item, nav, body, self = this;
            
    if (touch) {
            
      body = $('body');
      nav  = $('nav.main-nav');
      item = $('nav.main-nav li:first-child');
      
      nav.on('touchstart', function (e) {
                        
        if (self.isOpen) {
                    
          nav.removeClass('touch');
          body.removeClass('mobile-nav-open');
          
          self.isOpen = false;
          
        } else {
                    
          nav.addClass('touch');
          body.addClass('mobile-nav-open');
          
          self.isOpen = true;
          
          e.preventDefault();
        }
                
      });      
    }  
  }
  
};