var Flickity = require('Flickity'),
    util     = require('./util.js'),
    cookies  = require('./cookies.js'),
    $        = require('jquery')
    ;

/**
 * Data attribute to look for hi-res source
 */
var DATA_ATTR = "data-large-src";

/** 
 * Lightbox container class name 
 */
var CONTAINER_CLASS_NAME = "ev-lightbox";

/** 
 * Lightbox slide / cell class name 
 */
var CELL_CLASS_NAME = "ev-lb-item ev-cell";

/** 
 * Lightbox cell selector for Flickity
 */
var CELL_SELECTOR = ".ev-cell";

/** 
 * Pagination class name eg. [1 — 6]
 */
var PAGINATION_CLASS_NAME = "ev-lb-pagination";

/**
 * Lightbox close button class name 
 */
var CLOSE_CLASS_NAME = "ev-lb-close";

/**
 * Drag icon class 
 */
var DRAG_ICON_CLASS_NAME = "ev-lb-drag-icon";

/**
 * Drag SVG data 
 */
var DRAG_SVG = '<svg viewBox="0 0 52 52"><g opacity=".5"><path d="M10.4 30.1V19.5h3.1c3 0 5.1 1.5 5.1 5.2 0 3.9-2.4 5.4-5.1 5.4h-3.1zm2.9-1.2c2.1 0 3.8-1.2 3.8-4.2 0-3.1-1.7-4.1-3.6-4.1h-1.7v8.3h1.5zM24.6 23.2c-.2 0-.4-.1-.7-.1-1.3 0-2.4 1.1-2.4 2.7v4.3h-1.4v-8.3h1.1l.2 2.2c.3-1.5 1.4-2.5 3.2-2.4v1.6zM31.5 28.3c0 .6.3.8.6.8.2 0 .3 0 .5-.1v1.1c-.2.1-.4.1-.7.1-.9 0-1.5-.4-1.6-1.4-.4 1-1.4 1.4-2.5 1.4-1.5 0-2.5-.8-2.5-2.2 0-1.8 1.4-2.9 4.9-2.8v-1.1c0-1.1-.7-1.5-1.7-1.5s-1.6.5-1.7 1.4l-1.3-.2c.1-1.8 1.7-2.3 3.1-2.3 1.5 0 2.9.6 2.9 2.5v4.3zm-1.3-2c-2.4-.1-3.5.5-3.5 1.6 0 .8.5 1.3 1.5 1.3s2.1-.6 2.1-1.6v-1.3zM39.7 22.3c.4-.2.6-.7.6-1.3l1.2.4c-.1.8-.6 1.3-1.4 1.5.3.4.4 1 .4 1.6 0 1.9-1.5 2.9-3.3 2.9-.4 0-.9-.1-1.1-.1-.5.2-.6.5-.6.9 0 .5.3.8 1 .8h1.9c1.9 0 2.9.8 2.9 2.3 0 2.1-2.3 2.7-4.4 2.7-1.8 0-3.6-.5-3.6-2.2 0-1.1.8-1.9 2.1-2-.7-.1-1.1-.6-1.1-1.2 0-.7.3-1.2 1.2-1.4-1-.5-1.6-1.3-1.6-2.5 0-1.8 1.3-3 3.3-3 1.1-.1 1.9.1 2.5.6zm-2.6 10.6c1.7 0 3-.3 3-1.5 0-.7-.4-1.2-1.5-1.2h-1.7c-1.4 0-2.1.5-2.1 1.4 0 1 1 1.3 2.3 1.3zm.2-6.5c1.2 0 2-.6 2-1.9 0-1.3-.8-1.9-2-1.9s-1.9.7-1.9 1.9.7 1.9 1.9 1.9z"/></g><circle opacity=".5" fill="none" stroke="#000" stroke-width="1.508" stroke-miterlimit="10" cx="26" cy="26" r="25.2"/></svg>';

/**
 * Loader SVG data 
 */
var LOAD_SVG = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M25.25 6.46C14.934 6.46 6.57 14.827 6.57 25.145h4.068c0-8.07 6.543-14.615 14.615-14.615V6.46z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>';

/**
 * Shortcut reference to bind util function 
 */
var fnBind = util.fnBind;

/** 
 * En Ville Lightbox 
 * -----------------
 * Creates slides from image elements
 * Overlays on top and uses Flickity for interaction 
 */
module.exports = {
  
  /**
   * Parent node to attach to
   */
  parent: null,
  
  /** 
   * Selector for image sources 
   */
  selector: null,
  
  /**
   * Container for lightbox 
   * eg. '<div class="ev-lightbox"> ... </div>'
   */
  container: null,
  
  /**
   * List of image sources 
   */
  sources: [],
  
  /** 
   * Total number of slides 
   */
  numItems: 0,
  
  /**
   * Number of loaded images 
   */
  numLoaded: 0,
  
  /**
   * Drag icon element 
   */
  drag: null,
  
  /**
   * {Boolean} Is drag icon visible ? 
   */
  isDragIcon: false,
  
  /**
   * Preloader message element 
   */
  loader: null,
  
  /** 
   * Pagination number element 
   */
  pagination: null,
  
  /**
   * Flickity instance 
   */
  flickity: null,
  
  /**
   * Timeout id
   */
  timeout: null, 
  
  /**
   * Create image element and listen for load event 
   * @param {string} Image source 
   * @returns {object} Object containing image element 
   */
  getImageElement: function (src) {
    
    var img = new Image(), obj = {};
    
    obj.img = img;
    obj.isLoaded = false;
    
    img.onload = fnBind(this.onImageLoad, this, obj);
    img.src = src;
    
    if (img.complete) {
      this.onImageLoad.call(this, obj);
    } 
    
    return obj;
  },
  
  /**
   * Poll for loader and add complete 
   */
  checkLoader: function () {
    
    if (this.loader) {
      this.loader.className += ' complete';
      return true;
    }
    
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(fnBind(this.checkLoader, this), 100);
    
    return false;
  },
  
  /** 
   * Handle image loader and increment count 
   * @param {object} Object containing image element 
   */
  onImageLoad: function (obj) {
    
    if (obj.isLoaded) return;
    
    obj.isLoaded = true;
    
    ++this.numLoaded;
    
    if (this.numLoaded === this.numItems) {
            
      if (!this.loader) {
                        
        // Wait for it to become available 
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(fnBind(this.checkLoader, this), 100);
        
      } else {        
        this.loader.className += ' complete';
      }
    } 
  },  
  
  /**
   * Get index of clicked image source from sources list
   * @param {HTMLElement} Image source element 
   * @returns {Number} Index of source
   */
  getSourceIndex: function (imageSourceElement) {
    
    for(var i = 0; i < this.sources.length; ++i) {      
      if (imageSourceElement.getAttribute(DATA_ATTR) === this.sources[i]) return i;
    }
    
    return -1;
  },
  
  /**
   * Add click handler to image source to open lightbox
   * @param {HTMLElement} Element with image source data-attr
   */
  addInteraction: function (imageSourceElement) {
        
    imageSourceElement.addEventListener('click', fnBind(this.open, this, imageSourceElement));
    this.sources.push(imageSourceElement.getAttribute(DATA_ATTR));
  },
  
  
  /**
   * Create slide with hi-res image 
   * @param {HTMLElement} Element with image source data-attr  
   * @param {DocumentFragment} Fragment to append to
   */  
  createSlide: function (imageSourceElement, fragment) {
    
    var source = imageSourceElement.getAttribute(DATA_ATTR);
    
    if (!source) return;

    // Create slide div 
    var el = document.createElement('div');
    el.className = CELL_CLASS_NAME;
    el.setAttribute('style', 'background-image:url(' + source + ')');
    
    // Append image to slide
    el.appendChild(this.getImageElement(source).img);    
    // Append to document fragment 
    fragment.appendChild(el);    
       
  },
  
  /** 
   * Create drag icon for SVG 
   */
  createDragElement: function (fragment) {
    
    var drag = document.createElement('div');
    drag.className = DRAG_ICON_CLASS_NAME;
    drag.innerHTML = DRAG_SVG;
    this.drag = drag; 
    
    fragment.appendChild(drag);
  },
  
  /** 
   * Handle click of close button 
   */
  handleClose: function () {
    
    // Allow scrolling immediately
    this.enableScroll();
    
    // Remove `.open` class to transition out
    this.container.className = this.container.className.replace(/\bopen\b/, '').trim();
    
    // After transition time, destroy Flickity and DOM 
    setTimeout(fnBind(this.destroy, this), 500);    
  },
  
  /**
   * Create close button and event listener 
   * @param {Number} Starting index for pagination 
   * @param {DocumentFragment} Fragment to append to 
   */
  addUI: function (index, fragment) {
    
    var btn = document.createElement('div');
    btn.className = CLOSE_CLASS_NAME;
    btn.textContent = "Close";
    btn.addEventListener("click", fnBind(this.handleClose, this));
    
    var pagination = document.createElement('div');
    pagination.className = PAGINATION_CLASS_NAME;
    this.pagination = pagination;
    this.updatePagination(index);
    
    var loader = document.createElement('div');
    loader.className = "ev-loader";
    loader.innerHTML = LOAD_SVG;
    this.loader = loader;
    
    fragment.appendChild(loader);
    fragment.appendChild(btn);
    fragment.appendChild(pagination);
  },
  
  /** 
   * Add click listener to each image with source 
   * @param {string} Selector for image data sources 
   */
  addListeners: function (selector) {
    
    var images = document.querySelectorAll(selector);

    if (images.length) {

      for(var i = 0; i < images.length; ++i) {
        this.addInteraction(images[i]);
      }
    }
  },
  
  /**
   * Populate lightbox gallery from selector 
   * @param {Number} Starting index for pagination 
   * @param {string} DOM Selector for images 
   * @returns {DocumentFragment} Fragment containing slides 
   */
  populate: function (selector, index) {
    
    var images   = document.querySelectorAll(selector),
        fragment = document.createDocumentFragment();
    
    if (images.length) {
      
      this.numItems = images.length;
      this.numLoaded = 0;
      
      for(var i = 0; i < images.length; ++i) {
        this.createSlide(images[i], fragment);
      }
    }
    
    this.addUI(index, fragment);
    this.createDragElement(fragment);
    
    return fragment;
  },
  
  /**
   * Update pagination index 
   * @param {Number} Current index 
   */
  updatePagination: function (index) {
    
    if (this.pagination) {
      this.pagination.innerHTML = (index+1) + " &#8212; " + this.numItems;
    }
  },
  
  /**
   * Handler Flickity cell select event 
   * Update index in pagination 
   */
  onCellSelect: function () {
    this.updatePagination(this.flickity.selectedIndex);
  },
  
  /** 
   * Remove drag icon with the class
   */
  hideDragIcon: function () {
    if (this.isDragIcon) {
      this.drag.className = this.drag.className.replace(/\bactive\b/, '').trim();
      this.isDragIcon = false;
    }        
  },
  
  /**
   * Show drag icon and increment show count in cookies 
   */
  showDragIcon: function () {   
         
    // Have we seen this before? 
    var count = cookies.read('lightbox-count');
    count = parseInt(count, 10);
        
    if (isNaN(count)) {
      // First lightbox ever.
      count = 0;      
    } 
    
    // Increment view count and save to cookie
    cookies.create('lightbox-count', ++count);
      
    // Dont show drag after the first three times 
    if (count > 3) {
      
      this.isDragIcon = false;
      this.drag.className = this.drag.className.replace(/\bactive\b/, '').trim();
      return;
    }
    this.isDragIcon = true;
  },
  
  /**
   * Add left / right navigation on click 
   */
  onStaticClick: function (event, pointer, cellElement, cellIndex) {
    
    var viewportWidth = window.innerWidth;
    
    if (pointer.clientX < viewportWidth / 2) {
      this.flickity.previous();
    } else {
      this.flickity.next();
    }
    
    this.hideDragIcon();    
  },
  
  /** 
   * Remove drag icon on drag end 
   */
  onDragEnd: function () {    
    this.hideDragIcon();  
  },
  
  /**
   * Add interaction to all image data sources
   * @param {string} Image selector 
   * @param {HTMLElement} Optional parent container 
   */
  init: function (selector, parentNode) {
    
    this.parent = parentNode || document.body; 
    this.selector = selector;
    
    this.addListeners(selector);
  },
  
  attach: function (index) {
    
    this.container = document.createElement('div');
    this.container.className = CONTAINER_CLASS_NAME;
    
    this.container.appendChild(this.populate(this.selector, index));
    this.parent.appendChild(this.container);
    
    return this.container;
  },  
  
  /**
   * Close if ESC key is pressed
   */
  onKeyDown: function (e) {
    
    var ESC = 27;
    
    if (e.keyCode === ESC) {
      this.handleClose();
    }
  },
  
  /**
   * Update drag icon position with mouse cursor 
   */
  onMouseMove: function (e) {
    
    if (this.isDragIcon) {
      
      var offset = $(window).scrollTop(), 
          dragTop = (e.pageY - offset) - 51;
      
      this.drag.style.top = dragTop + 'px';
      this.drag.style.left = (e.pageX - 51) + 'px';
      
      if (!this.drag.className.match(/\bactive\b/)) this.drag.className += ' active';
    }
  },
  
  /**
   * For projects, a colour may have been set inline 
   */
  setBackgroundColour: function () {
    
    var body = $('body'), colour;
    
    if (body.hasClass('project')) {
      
      colour = $('.section-background:first-child').css('background-color');
      
      if (colour) {
        $(this.container).css('background-color', colour);    
      }
    }
    
    return null;
  },
  
  /** 
   * Open Lightbox to full scale  
   * Initiate Flickity for interaction 
   */
  open: function (imageSourceElement) {
            
    var index = this.getSourceIndex(imageSourceElement),
        container = this.attach(index), 
        self = this;
    
    if (!container) return;
    
    // Get background colour 
    this.setBackgroundColour();    

    // Init Flickity 
    this.flickity = new Flickity(container, {

      // Start at selected index
      initialIndex: index,
      // Specify selector class
      cellSelector: CELL_SELECTOR,
      // Do not snap cells to end position 
      freeScroll: false,
      // Hide buttons and dots 
      prevNextButtons: false, 
      pageDots: false
    });    
    
    // Listen to flickity events 
    this.flickity.on('cellSelect', fnBind(this.onCellSelect, this));
    this.flickity.on('staticClick', fnBind(this.onStaticClick, this));
    this.flickity.on('dragEnd', fnBind(this.onDragEnd, this));
         
    // Open container 
    requestAnimationFrame(function () {
      container.className += ' open';
      
      // Show drag icon on init    
      self.showDragIcon();   
    });
    
    // Listen to mousemove 
    $(window.document).on('mousemove', fnBind(this.onMouseMove, this));
    
    // Listen to key events 
    $(window.document).on('keydown', fnBind(this.onKeyDown, this));
    
    // Prevent scroll
    this.preventScroll();
  },
  
  /**
   * Add class to prevent scrolling while lightbox open
   */
  preventScroll: function () {
    window.document.body.className += ' noscroll';
  },
  
  /**
   * Remove no scroll class to enable scrolling 
   */
  enableScroll: function () {    
    window.document.body.className = window.document.body.className.replace(/\bnoscroll\b/, '').trim();
  },
  
  /**
   * Destroy Flickity and remove from DOM 
   */
  destroy: function () {
    
    if (this.flickity) {
      this.flickity.destroy();
      this.flickity = null;
    }
    
    $(window.document).off('mousemove');
    $(window.document).off('keydown');
    
    this.loader = null;
    this.pagination = null;
    
    if (this.container && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
      this.container = null;
    }
  }
  
};