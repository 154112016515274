var $          = require('jquery'),
    fastclick  = require('fastclick'),
    Flickity   = require('flickity-imagesloaded'),
    Isotope    = require('isotope-layout'),
    EVLightbox = require('./lightbox.js'),
    imgsLoaded = require('imagesLoaded'),
    Intro      = require('./intro.js'),
    raf        = require('raf'),
    scrll      = require('./scroll.js'),
    mobilenav  = require('./mobilenav.js'),
    cookies    = require('./cookies.js'),
    util       = require('./util.js')
    ;

/**
 * Use data-[width|height] attrs to set aspect ratio
 */
function applyAspectRatio() {
  $('img').each(function (i, el) {
    var $el = $(el);
    
    if ($el.data('width')) {
      var $wrapper = $el.closest('.image-wrapper');
      var ratio = $el.data('height') / $el.data('width') * 100;
      
      if (!$wrapper.hasClass('image-16-5-wrapper')) {
        $wrapper.css('padding-bottom', ratio + '%');     
      }   
    }
  });
}

/**
 * Add complete class to all loaded images 
 */
function setupImages() {
  
  applyAspectRatio();
    
  imgsLoaded(document.querySelector('body'), function () {        
    setTimeout(function () {
      $('img').addClass('image-complete');
      $('img').closest('.image-wrapper').addClass('complete');      
    });
  });
}  

/**
 * Queue of selected filters 
 */
var filters = [];

/**
 * Add to filter stack
 */
function addFilter(filter) {
  
  for(var i = 0; i < filters.length; ++i) {    
    if (filters[i] === filter) return;
  }
  
  filters.push(filter);
}

function removeFilter(filter) {
  var index = filters.indexOf(filter);
  filters.splice(index, 1);
}

function getFilters() {
  
  if (filters.length >= 1){
    return '.' + filters.join(', .');
  } else {
    return '';
  }
}


/**
  * Setup Isotope grid for filtering 
  */
function setupIsotope(selector, itemSelector) {
      
  if (document.querySelector(selector)) {
    
    imgsLoaded(document.querySelector(selector), function () {
        
      // Show grid images once loaded 
      $(itemSelector).addClass('complete');

      var iso = new Isotope(selector, {
        itemSelector: itemSelector,
        layoutMode: 'masonry'            
      });

      // Add listeners to filters 
      $('[data-filter]').on('click', function () {
        
        var filter = $(this).data('filter'),
            self = $(this);
            
        if (filter.indexOf('*') === -1) {
          
          self.parent().find('li').eq(0).removeClass('selected');
          
          // Selected or no? 
          if (self.hasClass('selected')) {
            // Remove           
            self.removeClass('selected');
            removeFilter(filter);
            
            // Check 'all' if no filters
            if (filters.length < 1) {
              self.parent().find('li').eq(0).addClass('selected');
            }
            
          } else {
            self.addClass('selected');
            // Add to stack of filters 
            addFilter(filter);
          }
                             
        } else {
          
          if (self.hasClass('selected')) return;
          
          // All is selected 
          self.parent().find('li').removeClass('selected');
          self.addClass('selected');
          
          filters = [];
        }
                
        iso.arrange({
          filter: getFilters()          
        });        
      });
    });    
    
  } 
}

/** 
  * Setup Flickity as horizontal scroller
  * With no page dots or buttons  
  * @param {string} DOM Selector 
  * @param {string} DOM Cell selector 
  */
function setupFlickity(selector, cellSelector) {

  if (document.querySelector(selector)) {
    
    new Flickity(selector, {
      
      // Start at second one 
      initialIndex: 0,
      // Specify selector class
      cellAlign: 'left',
      cellSelector: cellSelector,
      // Do not snap cells to end position 
      freeScroll: true,
      // Hide buttons and dots 
      prevNextButtons: false, 
      pageDots: false,
      imagesLoaded: true
    });
  }  
}

/**
 * Init Lightbox for elements with data-large-src attribute 
 */
function setupLightbox() {
  
  var lb = Object.create(EVLightbox);
  lb.init('[data-large-src]');
}

/**
 * Init intro section and show all headers
 */
function setupIntro() {
  
  var intro = Object.create(Intro);  
  // Have we seen this before? 
  if (cookies.read('intro') === 'hide') {
    intro.hide('.intro');
  } else {
    intro.init('.intro');
  }
  
  // Set as seen 
  // cookies.create('intro', 'hide');
} 

/**
 * Setup mobile nav interaction 
 */
function setupMobileNav() {
  mobilenav.init();
}

/**
 * Start animation loop 
 */
var isFirst = true;
function startAnimation() {
  
  scrll.init();
  
  raf(function tick() {
    
    // Fade section container in on load.
    if (isFirst) {
      $('.section-background').get(0).classList.add('ready');
      
      var contact = $('.section-background.contact');
      if (contact.length > 0) {
        contact.get(0).classList.add('ready');
      }
      
      isFirst = false;
    }
    
    scrll.update();
    raf(tick);
  });
}

function isProject() {    
  return $('body').hasClass('project');
}

/**
 * Add interaction to contact link 
 * Scroll down to footer unless on project page
 * Project page contact links to about 
 */
function setupContactLink() {
  
  $('nav.secondary-nav .contact a').on('click', function (e) {
    
    if (!isProject()) {
      
      // Animate to bottom of page to show contact
      $('html, body').animate({
        scrollTop: util.getDocumentHeight()
      }, "slow");
      
      // Prevent default behaviour of going to href link
      e.preventDefault();
      return false;
    } 
  });
}

/**
  * Setup page on DOM ready
  */
$(function () {

  /** 
   * Attach FastClick.js to DOM body
   */
  fastclick(document.body);
  
  /**
   * Mobile interaction for navigation 
   */
  setupMobileNav();
  
  /**
   * Add interactio to Contact link 
   */
  setupContactLink();
  
  /**
   * Initialize Flickity
   */
  setupFlickity('.more-work-gallery', '.gallery-cell');
  
  /** 
   * Preload all images 
   */
  setupImages();
  
  /**
   * Initialize Lightbox 
   */
  setupLightbox();
  
  /**
   * Initialize Isotope
   */
  setupIsotope('.filter-grid', '.grid-item');

  /** 
   * Play through intro headers 
   */
  setupIntro();
  
  /**
   * Start animation loop 
   */
  startAnimation();

});