var $    = require('jquery'),
    util = require('./util.js');
    
/**
 * Default selector for intro container 
 */
var DEFAULT_SELECTOR = '.intro';
    
/**
 * Initial pause for `En Ville`
 */
var INITIAL_INTERVAL = 900;

/**
 * Interval for heading switch
 */
var INTERVAL = 100;

/** 
 * Pause at end of intro 
 */
var END_INTERVAL = 500;
     
     
module.exports = {
  
  /** 
   * Selector intro container, eg. '.intro'
   */
  selector: null, 
  
  /**
   * Cache jQuery h1 elements 
   */
  elements: null,
  
  /**
   * Save timeout id for showing headers 
   */
  timeout: null,
  
  /**
   * Interval in ms between headings 
   */
  interval: INITIAL_INTERVAL,
  
  /**
   * Current heading showing num 
   */
  index: 0,
  
  /**
   * Save previous element to remove active class when done 
   */
  prevElement: null,
  
  /**
   * Show next heading and remove last heading 
   * Set timeout to show the next, if any 
   */
  nextHeading: function () {
    
    if (this.prevElement) {
      
      this.prevElement.removeClass('active');
      this.prevElement = null;
      
      // Call this again to show the next heading after interval 
      this.timeout = setTimeout(util.fnBind(this.nextHeading, this), this.interval);
      
      return;
    }
    
    // Make next element active 
    this.elements.eq(this.index).addClass('active');
    
    // Save this element     
    this.prevElement = this.elements.eq(this.index);

    if (this.index++ < this.elements.length) {       
      
      // Call this again to show the next heading after interval 
      this.timeout = setTimeout(util.fnBind(this.nextHeading, this), this.interval);
      
      // Set to shorter, regular intervals after the first one
      this.interval = INTERVAL;
    } else {
      
      // Fade out entire element
      this.timeout = setTimeout(util.fnBind(this.introComplete, this), END_INTERVAL);
    }
  },
  
  /** 
   * Remove intro from DOM 
   */
  removeFromDOM: function () {
    $(this.selector).remove();
  },
  
  /** 
   * On complete, fade out container and remove from DOM 
   */
  introComplete: function () {
         
    // Fade out
    $(this.selector).addClass('complete');
    
    // Remove from DOM after 500ms 
    setTimeout(util.fnBind(this.removeFromDOM, this), 500);
  },
  
  /**
   * Hide immediately and remove from DOM
   */
  hide: function (selector) {
    this.selector = selector;
    this.removeFromDOM();
  },
  
  /**
   * Init intro and trigger first heading to show 
   */
  init: function (selector) {
    
    this.selector = selector || DEFAULT_SELECTOR; 
    this.elements = $(selector + ' h1');
    
    if (this.elements.length > 0) {
      
      this.nextHeading();
    } else {
      
      this.introComplete();
    }
  }
  
};
